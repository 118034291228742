module services {
    export module applicationcore {
        export class lookupService implements interfaces.applicationcore.ILookupService {
            static $inject = ["$uibModal"];

            constructor(public $uibModal: ng.ui.bootstrap.IModalService) {

            }

            openLookup(lookupConfig: interfaces.applicationcore.ILookupDirective) {
                return this.$uibModal.open({
                    animation: true,
                    templateUrl: 'templates/modules/applicationcore/lookupView.html',
                    controller: 'lookupCtrl',
                    controllerAs: 'LookupCtrl',
                    resolve: {
                        items: function () {
                            return lookupConfig;
                        }
                    }
                });
            }

            //allowAllTypes indicated if user can filter by different tariffCodeType types or not.
            openTariffLookup(countryId: number, tariffCodeType: Enum.EnumTariffCodeType, allowAllTypes: boolean, code?: string,entityId?:number,codeTypeClassificationValueId?:number) {
                return this.$uibModal.open({
                    animation: true,
                    templateUrl: 'templates/modules/applicationcore/tariffLookupView.html',
                    controller: 'tariffLookupCtrl',
                    controllerAs: 'tariffLookupCtrl',
                    size: "lg",
                    resolve: {
                        countryId: countryId,
                        tariffCodeType: tariffCodeType,
                        allowAllTypes: allowAllTypes,
                        measureCode: () => {return code;},
                        entityId:entityId,
                        codeTypeClassificationValueId: codeTypeClassificationValueId
                    }
                });
            }
        }
    }
    angular.module("app").service("lookupService", services.applicationcore.lookupService);
}